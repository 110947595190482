import { Component } from '@angular/core';
import { UCUI } from '@data-terminal/shared-models';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
declare const UC_UI: UCUI;

@Component({
    standalone: true,
    imports: [HdmuiComponentsModule, TranslateModule],
    selector: 'app-legal-line',
    templateUrl: './legal-line.component.html',
    styles: `
        :host {
            margin-top: auto;
        }
    `,
})
export class LegalLineComponent {
    public readonly docsUrl =
        'https://www.heidelberg.com/global/de/global_content/heidelberg_customer_portal_1/home/terms_of_use_and_privacy_policy_1/country_list.html';
    public readonly feedbackMail = 'hei.portal@heidelberg.com';

    constructor(private readonly translateService: TranslateService) {}

    public showSecondLayer(): void {
        UC_UI.updateLanguage(this.translateService.currentLang);
        UC_UI.showSecondLayer();
    }
}
