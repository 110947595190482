<hdmui-legal-line
    [useOfTermsLink]="docsUrl"
    [privacyPolicyLink]="docsUrl"
    [imprintLink]="docsUrl"
    [showContact]="false"
    [showFeedback]="true"
    [feedbackLink]="'mailto:' + feedbackMail"
    ><div customLinkContainer>
        <a (click)="showSecondLayer()">{{ '*.cookie.settings' | translate }}</a>
    </div></hdmui-legal-line
>
